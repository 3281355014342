import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';

import { Filter, OptionsFilter, TextFilter } from '@playq/octopus-common';
import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { App, AppsFilterField, AppsSort, QueryAppsResponse } from '@playq/octopus2-apps';

import { services2 } from '/api/services2';
import {
  useEitherQuery,
  useMutateQueryDataAfterEntityDeleting,
  UseQueryOptionsExtended,
  useQueryPrefetch,
} from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';
import { iteratorMaxLimit } from '/constants';

import { appsQueryTags } from './constants';

const emptyArray: [] = [];

const defaultQuery = {
  iterator: new OffsetLimit({ limit: iteratorMaxLimit, offset: 0 }),
  sortBy: [],
  filterBy: {},
};

const convertAppsFilterBy = (filterBy: { [key: string]: Filter }) => {
  const newFilterBy = { ...filterBy };

  const marketsFilter = filterBy[AppsFilterField.Market] as OptionsFilter;
  const platformsFilter = filterBy[AppsFilterField.Platform] as OptionsFilter;

  const hasFilterByMarket = !_.isNil(marketsFilter?.options);
  const hasFilterByPlatform = !_.isNil(platformsFilter?.options);

  if (hasFilterByMarket) {
    const text = `/(${marketsFilter.options.join('|')})`;
    newFilterBy[AppsFilterField.Market] = new TextFilter({ text });
  }

  if (hasFilterByPlatform) {
    const text = `/(${platformsFilter.options.join('|')})`;
    newFilterBy[AppsFilterField.Platform] = new TextFilter({ text });
  }

  return newFilterBy;
};

export const useAppsQuery = (
  iterator: OffsetLimit = defaultQuery.iterator,
  sortBy: AppsSort[] = defaultQuery.sortBy,
  filterBy: {
    [key: string]: Filter;
  } = defaultQuery.filterBy,
  options?: UseQueryOptionsExtended<QueryAppsResponse | undefined, GenericFailure | Error>
) => {
  const queryClient = useQueryClient();

  const convertedFilterBy = useMemo(() => convertAppsFilterBy(filterBy), [filterBy]);

  const keys = useMemo(
    () => appsQueryTags.concat(iterator, sortBy, convertedFilterBy),
    [iterator, sortBy, convertedFilterBy]
  );

  const { mutate, refetch, ...res } = useEitherQuery(
    keys,
    () => {
      return services2.appsService.queryApps(iterator, sortBy, convertedFilterBy);
    },
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );

  const total = useMemo(() => res.data?.total ?? 0, [res.data?.total]);

  const { nextKeys } = useQueryPrefetch({
    keys,
    enabled: options?.enablePrefetch,
    total,
    args: [iterator, sortBy, convertedFilterBy],
    serviceMethod: services2.appsService.queryApps.bind(services2.appsService),
  });

  const removeQueries = useCallback(() => {
    queryClient.removeQueries(appsQueryTags);
  }, [queryClient]);

  const mutateDeletedApp = useMutateQueryDataAfterEntityDeleting({
    queryClient,
    nextPageQueryKey: nextKeys,
    total,
    iterator,
    mutate,
    refetch,
    getID: (a?: App) => a?.id,
    getEntities: (r: QueryAppsResponse) => r.entities,
    setEntities: (r: QueryAppsResponse, newEntities: App[]) => {
      r.entities = newEntities;
    },
    createNewResponse: (prevResponse: QueryAppsResponse) => new QueryAppsResponse(prevResponse.serialize()),
  });

  return {
    ...res,
    apps: res.data?.entities || emptyArray,
    features: res.data?.features || {},
    total,
    tags: keys,
    mutateDeletedApp,
    removeQueries,
    refetch,
  };
};
