import { useEffect, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { AttachmentSearchQuery, AttachmentsResponse } from '@playq/services-beetle';
import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { AppID, Environment } from '@playq/octopus-common';

import { UseQueryOptionsExtended, useEitherQuery, useQueryPrefetch } from '/api/service-hooks';
import { services2 } from '/api/services2';
import { snackbarService } from '/common/snackbarService';
import { calculateTotalAttachment } from '/component/Support/BeetlesLookup/helpers';

export const searchGlobalAttachmentQueryKeys: unknown[] = ['bettle', 'support', 'search', 'attachments', 'global'];

export const useSearchAttachments = (
  appId: AppID,
  query: AttachmentSearchQuery,
  env: Environment,
  iterator: OffsetLimit,
  options?: UseQueryOptionsExtended<AttachmentsResponse | undefined, GenericFailure | Error>
) => {
  const queryClient = useQueryClient();
  const keys = useMemo(() => searchGlobalAttachmentQueryKeys.concat(env, query, iterator), [env, query, iterator]);
  const { mutate, refetch, ...res } = useEitherQuery(
    keys,
    () => services2.audienceSupportService.searchAttachments(appId, query, iterator, env),
    {
      keepPreviousData: true,
      onError: (err) => {
        const error = err?.message || new Error('Could not retrieve issues');
        snackbarService.genericFailure(error);
      },
      ...options,
    }
  );
  const { nextIterator, preFetchData, nextKeys, setPreFetchData } = useQueryPrefetch({
    keys,
    enabled: true,
    total: res.data?.attachments.length,
    args: [appId, query, iterator, env],
    serviceMethod: services2.audienceSupportService.searchAttachments.bind(services2.audienceSupportService),
    hasMore: res.data?.attachments.length === iterator.limit ? true : undefined,
  });
  useEffect(() => {
    if (nextKeys && res.data?.attachments.length === iterator.limit) {
      setPreFetchData(queryClient.getQueryData(nextKeys));
    }
    return () => setPreFetchData(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iterator.limit, queryClient, res.data?.attachments.length, setPreFetchData]);
  const attachments = res.data?.attachments || [];

  const total = calculateTotalAttachment(
    nextIterator,
    attachments.length,
    iterator,
    preFetchData?.attachments.length ?? iterator.limit
  );

  return {
    attachments,
    refetch,
    total,
    ...res,
  };
};
