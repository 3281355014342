import { CommonSuccess, GenericFailure } from '@playq/services-shared';

import { IInvokeScriptsHook } from '/common/scripts/types';
import { UseMutationOptionsExtended } from '/api/service-hooks';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';

import { appsPackagesService, appsPackagesServiceName } from './constants';
import { IAppsPackageDemoteOptionsVariables, IDemotePackageParams } from './types';

export const useAppsPackageDemoteScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, IAppsPackageDemoteOptionsVariables>
) =>
  useEitherMutationScripted<IDemotePackageParams, CommonSuccess, IInvokeScriptsHook>({
    serviceMethod: appsPackagesService.demotePackage.bind(appsPackagesService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: appsPackagesServiceName,
      mutationType: 'package demotion',
    },
    options,
  });
