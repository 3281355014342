import {
  Traits,
  TraitGroup,
  TraitEntry,
  TraitEntryDateTime,
  TraitEntryString,
  TraitEntryInt,
} from '@playq/octopus2-analytics';

function createTraitEntry<T extends TraitEntry>(Class: new () => T, name: string, desc: string): T {
  const entry = new Class();
  entry.name = name;
  entry.desc = desc;
  return entry;
}

function createTraitGroup(name: string, entries: TraitEntry[]): TraitGroup {
  const group = new TraitGroup();
  group.name = name;
  group.entries = entries;
  return group;
}

const traits = {
  startsAt: createTraitEntry(TraitEntryDateTime, 'startsAt', 'Actual recurrence start, not the general field'),
  endsAt: createTraitEntry(TraitEntryDateTime, 'endsAt', 'Actual recurrence end, not the general field'),
  name: createTraitEntry(TraitEntryString, 'name', 'Game event name'),

  recurrence: createTraitEntry(TraitEntryInt, 'recurrence', 'index from 0 to X, whenever this is evaluated'),
  variant: createTraitEntry(TraitEntryInt, 'variant', 'ID of selected variant'),
  exposure: createTraitEntry(TraitEntryInt, 'exposure', 'Random number from 0 to 1 that represents users exposure'),
};

const commonEntries = [traits.startsAt, traits.endsAt, traits.name];

const groups = {
  gameEvent: createTraitGroup('$self', [...commonEntries, traits.recurrence]),
  experiment: createTraitGroup('$self', commonEntries),
  experimentDetails: createTraitGroup('$experiment', [traits.variant, traits.exposure]),
};

export const hardcodedGameEventTraits = new Traits();
hardcodedGameEventTraits.groups = [groups.gameEvent];

export const hardcodedExperimentTraits = new Traits();
hardcodedExperimentTraits.groups = [groups.experiment, groups.experimentDetails];
