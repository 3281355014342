import { IconButton, styled } from '@mui/material';

export const HelperIconButton = styled(IconButton)`
  background: ${({ theme }) => theme.palette.background.default};
  padding: 0;

  &:hover {
    background: ${({ theme }) => theme.palette.background.default};
  }
`;

export const IconWrapper = styled('div')`
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: ${({ theme }) => theme.zIndex.modal};
  padding: ${({ theme }) => theme.spacing(1)};
  background: transparent;
`;
