import { useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { ClusterizationRun, ClusterizationRunId } from '@playq/services-beetle';
import { Environment } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export const retrieveRunKeys = ['retrieve', 'run'];
export const useRetrieveRun = (
  id: ClusterizationRunId,
  env: Environment = Environment.Live,
  options?: UseQueryOptions<ClusterizationRun | undefined, GenericFailure | Error>
) => {
  const keys = useMemo(() => retrieveRunKeys.concat(id.id, env), [env, id]);

  const { mutate, ...res } = useEitherQuery(
    keys,
    () => services2.audienceRequestsClusterizationService.retrieveRun(id, env),
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );
  const clusterizationRun = res.data;
  return { ...res, clusterizationRun };
};
